.email-verification-header-text {
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  color: #262a3d;
}
.email-verification-note-text {
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 400;
  font-size: 16px;
  align-self: left;
  vertical-align: top;
  line-height: 24px;
  opacity: 0.5;
}

.email-verification-subheader-text {
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 16px;
  align-self: left;
  vertical-align: top;
  line-height: 24px;
  opacity: 0.5;
}
