@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

/*
    APP COLORS
*/
$DARKBLUE: #0D47A1;
$AQUABLUE: #0091EA;
$LIGHTBLUE: #00B0FF;
$SKYBLUE: #80D8FF;
$FULLWHITE: #ffffff;
$OFFWHITE: #fafafa;
$LIGHTGREYBACKGROUND: #fbfbfd;
$BACKGROUNDMUTED: #f7f7fa;
$DIVIDERGREY: #e2e6ed;
$OFFBLACK: #212121;
$AQUABLACK: #1d1d1f;
$FONTTITLEBLACK: #262A3D;
$FONTBLACK:  #333333;
$FONTMUTED:  #262A3D80;
$AQUAYELLOW: #FFD600;

/*
    APP FONT
*/
$APPFONTDEFAULT: "Montserrat";

.default-header-text {
    font-family: $APPFONTDEFAULT;
    font-weight: 600;
    font-style: normal;
    font-size: 24px;
    line-height: 32px;
    color: $FONTTITLEBLACK
  }

.default-subheader-text-600 {
    font-family: $APPFONTDEFAULT;
    font-weight: 600;
    font-style: normal;
    font-size: 20px;
    line-height: 20px;
    color: $FONTTITLEBLACK
  }

  .default-header-text-500 {
    font-family: $APPFONTDEFAULT;
    font-weight: 500;
    font-style: normal;
    font-size: 32px;
    line-height: 32px;
    color: $FONTTITLEBLACK
  }

.default-section-text-muted-500 {
    font-family: $APPFONTDEFAULT;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: $FONTMUTED
  }

.default-section-text-500 {
    font-family: $APPFONTDEFAULT;
    font-weight: 500;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
    color: $FONTBLACK
  }

.default-section-text {
    font-family: $APPFONTDEFAULT;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    color: $FONTBLACK
  }

/*
    SPACING
*/
.centerA {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

.ceneterV {
    margin: 0;
    position: absolute;
    top: 50%;
  }

/*
    COLORS
*/

.backgroundGrey {
  background: $BACKGROUNDMUTED;
}


// .accordion-button {
//   &:not(.collapsed) {
//       color: $DARKBLUE;
//       background-color: $BACKGROUNDMUTED;
//       box-shadow: none;
//   }
// }