@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.sample-invest-header-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  line-height: 20px;
  color: #262a3d;
}

.sample-invest-subheader-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: #262a3d;
  opacity: 0.5;
}

.stepper-container {
  background-color: #ffffff;
  border-radius: 10px;
}

.stepper-hyperlink-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #0d47a1;
}

.stepper-hyperlink-text:hover {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #0c3690;
}
