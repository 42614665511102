.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #ffffff !important;
}
.css-1wys6q9-MuiButtonBase-root-MuiButton-root {
  font-family: "Montserrat";
  color: #262a3d;
}
// .MuiPaper-root .MuiPaper-elevation .MuiPaper-elevation4 .MuiAppBar-root .MuiAppBar-colorPrimary .MuiAppBar-positionStatic .css-hip9hq-MuiPaper-root-MuiAppBar-root{

// }
