@use "../../../../../../../../utilities/scss/variables";
@import "../../../../../../../../utilities/scss/global";

.ConfidentialityAgreementPage-container {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}

.fillScreen {
  min-height: 100vh;
}

.rectangle1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  height: 100%;
  background-color: #f0f4fd;
}

.rectangle2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  background-color: #ffffff;
  display: flex;
}

.ConfidentialityAgreement-container {
  box-sizing: inherit;
  box-shadow: none;
  margin-left: 100px;
  margin-right: 10px;
  max-width: 444px;
}

.Confidentiality-header {
  margin-bottom: 32px;
}

.Confidentiality-header-text {
  font-family: variables.$primary-font-family;
  font-weight: 600;
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  color: #262a3d;
}

.document-container {
  overflow-y: auto;
  height: 348px;
  max-height: 348px;
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-bottom: 24px;
}

.document-text-content {
  font-family: variables.$primary-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #262a3d;
}

.checkBox-bar {
  display: flex;
  align-items: center;
  max-height: 39px;
  margin-bottom: 32px;
}

.checkBox-container {
  width: 24px;
  height: 24px;
}

.checkBox-border {
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0d47a1;
  box-sizing: border-box;
  border-radius: 2px;
}

.checkBox-label {
  font-family: variables.$primary-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  color: #262a3d;
}

.button-container {
  float: left;
  max-width: 120px;
}

.signup-bar {
  height: 40px;
  margin-bottom: 72px;
}

.progress-container {
  display: flex;
  max-width: 280px;
  max-height: 2px;
}

.progress-bar-completed {
  float: left;
  height: 0px;
  width: 40px;
  border: 2px solid #0d47a1;
}

.progress-bar-empty {
  float: left;
  height: 0px;
  width: 40px;
  border: 2px solid #dadde1;
}

.progress-spacer {
  width: 13px;
}

.sign-in-text {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #262a3d;
}

.back-arrow-section {
  display: flex;
  max-width: 16px;
  max-height: 14px;
  margin-bottom: 40px;
}

.display-linebreak {
  white-space: pre-line;
}

.signature-font {
  font-family: "Pinyon Script", cursive;
  font-size: 25px;
  font-weight: 400;
}
