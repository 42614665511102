@use "../../../../../../../../utilities/scss/variables";
@import "../../../../../../../../utilities/scss/global";

.QuestionnairePage-container {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
.fillScreen {
  height: 100vh;
}
.rectangle1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  height: 100%;
  background-color: #f0f4fd;
}
.rectangle2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  background-color: #ffffff;
  display: flex;
}
.QPQuestionnaire-container {
  box-sizing: inherit;
  box-shadow: none;
  margin-left: 100px;
  margin-right: 10px;
  max-width: 440px;
}
.header-join {
  margin-top: 12px;
}
.join-text {
  font-family: variables.$primary-font-family, sans-serif;
  font-weight: bold;
  font-size: 32px;
}
.join-title {
  width: 295px;
  height: 32px;
  font-family: variables.$primary-font-family, sans-serif;
  font-weight: bold;
  font-size: 32px;
}
.subheader {
  padding-top: 16px;
  padding-bottom: 32px;
}
.sub-text-gray {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  opacity: 0.5;
}
.sub-text {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}
.selection-1 {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 6px;
}
.selection-1-selected {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid #0d47a1;
  box-sizing: border-box;
  border-radius: 6px;
}
.new-selection-1-text {
  cursor: default;
  font-family: variables.$primary-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.75;
  //   padding-top: 15px;
  //   padding-left: 10px;
  //   padding-right: 10px;
  //   padding-bottom: 15px;
}
.selection-2 {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 6px;
}
.selection-2-selected {
  display: flex;
  align-items: center;
  justify-content: left;
  border: 1px solid #0d47a1;
  box-sizing: border-box;
  border-radius: 6px;
}
.selection-2-text {
  cursor: default;
  font-family: variables.$primary-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  opacity: 0.75;
  padding-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 15px;
}
.regulatory-info-container {
  display: flex;
  align-items: center;
  justify-content: left;
  box-sizing: border-box;
  max-width: 368px;
  margin-bottom: 32px;
}
.regulatory-info-text {
  font-family: variables.$primary-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
  opacity: 0.5;
}
.button-container {
  float: left;
  max-width: 120px;
}
.signup-bar {
  height: 40px;
  margin-bottom: 72px;
}
.sign-in-text {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #262a3d;
}
.progress-container {
  display: flex;
  max-width: 280px;
  max-height: 2px;
}
.progress-bar-completed {
  float: left;
  height: 0;
  width: 100%;
  border: 2px solid #0d47a1;
}
.progress-bar-empty {
  float: left;
  height: 0px;
  width: 100%;
  border: 2px solid #dadde1;
}
.progress-spacer {
  width: 13px;
}

.account-selection-error-text {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}
