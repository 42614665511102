@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

.blog-posts-not-found-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
  color: #262a3d;
}
