@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.left {
  height: 100%;
  width: 100%;
}
.fillScreen {
  height: 100vh;
}

.login-container {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
.fill {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  height: 100%;
  z-index: 2;
}
.rectangle1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  height: 100%;
}
.rectangle1 .img {
  width: 100%;
  height: 100%;
}
.rectangle2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  background-color: #ffffff;
  display: flex;
}
.header-welcome {
  margin-top: 12px;
}
.welcome-text {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 32px;
}
.welcome-title {
  width: 295px;
  height: 32px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 32px;
}
.login-input {
  box-sizing: inherit;
  box-shadow: none;
  margin-left: 100px;
  margin-right: 10px;
}
.login-input-1 {
  box-sizing: inherit;
  box-shadow: none;
  margin-bottom: 24px;
}
.login-input-2 {
  box-sizing: inherit;
  box-shadow: none;
  margin-bottom: 32px;
}
.subheader {
  padding-top: 16px;
  padding-bottom: 32px;
}
.enter-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
  align-self: left;
  vertical-align: top;
  opacity: 0.5;
  line-height: 20px;
  line-height: 82%;
}
.input-container {
  width: 100%;
  min-width: 39px;
  max-width: 640px;
}
.input-label {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 18px;
  color: #262a3d;
}
.input-2-container {
  vertical-align: middle;
}
.forgotPassword {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  color: #262a3d;
}
.forgot-password {
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.5;
  color: #262a3d;
  margin-top: auto;
  margin-bottom: auto;
}
.padding {
  padding-bottom: 12px;
}
.input-box-container {
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.input-box-containerFocus {
  border: 1px solid #0d47a1;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.input-box {
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
.internal-input {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  height: 100%;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
  border-top-color: initial;
  border-top-style: none;
  border-top-width: initial;
  border-right-color: initial;
  border-right-style: none;
  border-right-width: initial;
  border-bottom-color: initial;
  border-bottom-style: none;
  border-bottom-width: initial;
  border-left-color: initial;
  border-left-style: none;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 15px;
  line-height: 18px;
  color: #262a3d;
  width: 100%;
}
.button-container {
  width: 120px;
  height: 40px;
}
.signup-bar {
  height: 40px;
}
.signUpText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #262a3d;
}
.sign-up-text {
  float: right;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #262a3d;
  margin-top: auto;
  margin-bottom: auto;
}

.error-field-container {
  display: flex;
  width: 100%;
}
.LoginForm-field-error-container {
  float: left;
}
.email-field-error-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #cc0000;
}
.btn-class {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 16px !important;
  background-color: #0d47a1 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}

.btn-class:hover {
  background-color: #0e3690 !important;
}
.btn-class:focus,
.btn-class:active {
  outline: none !important;
  box-shadow: none !important;
}
.email-field-title-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #262a3d;
}

.forgot-password-header-text {
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  color: #262a3d;
}
.forgot-password-note-text {
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 400;
  font-size: 16px;
  align-self: left;
  vertical-align: top;
  line-height: 24px;
  opacity: 0.5;
}

.forgot-password-subheader-text {
  font-family: -apple-system, BlinkMacSystemFont;
  font-weight: 500;
  font-size: 16px;
  align-self: left;
  vertical-align: top;
  line-height: 24px;
  opacity: 0.5;
}
