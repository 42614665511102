@import '../../../../Styles/Variables/GlobalStyles.scss';


.overview-header-text {
  font-family: $APPFONTDEFAULT;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
}

.overview-section-text {
  font-family: $APPFONTDEFAULT;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

.display-linebreak {
  white-space: pre-line;
}

.bordergrey {
    border: 1px solid $DIVIDERGREY;
    margin-left: 0px;
}

.border-left-bottom-grey {
    border-bottom: 1px solid $DIVIDERGREY;
    border-left: 1px solid $DIVIDERGREY;
    margin-left: 0px;
}

.border-right-top-grey {
    border-top: 1px solid $DIVIDERGREY;
    border-right: 1px solid $DIVIDERGREY;
    margin-left: 0px;
}

.border-top-grey {
  border-top: 1px solid $DIVIDERGREY;
  margin-left: 0px;
}

.border-sides-bottom-grey {
  border-right: 1px solid $DIVIDERGREY;
  border-bottom: 1px solid $DIVIDERGREY;
  border-left: 1px solid $DIVIDERGREY;
  margin-left: 0px;
}


@media (max-width: 1385px) {
    .default-section-text-muted-500 {
        font-family: $APPFONTDEFAULT;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        line-height: 16px;
        color: $FONTMUTED
      }

    .default-section-text-500 {
        font-family: $APPFONTDEFAULT;
        font-weight: 500;
        font-style: normal;
        font-size: 15px;
        line-height: 16px;
        color: $FONTBLACK
      }

    .default-section-text {
        font-family: $APPFONTDEFAULT;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
        line-height: 16px;
        color: $FONTBLACK
      }

}
@media (max-width: 760px) {

    .mobile-padding-left {
      padding-left: 1rem;
    }

    .default-section-text-muted-500 {
        font-family: $APPFONTDEFAULT;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: $FONTMUTED
      }

    .default-section-text-500 {
        font-family: $APPFONTDEFAULT;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: $FONTBLACK
      }

    .default-section-text {
        font-family: $APPFONTDEFAULT;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        line-height: 16px;
        color: $FONTBLACK
      }
}


@media (max-width: 450px) {
  .mobile-padding-left {
    padding-left: 1rem;
  }

  .mobile-margin-top {
    margin-top: .85rem;
  }

  .default-section-text-muted-500 {
      font-family: $APPFONTDEFAULT;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      color: $FONTMUTED
    }

  .default-section-text-500 {
      font-family: $APPFONTDEFAULT;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      line-height: 14px;
      color: $FONTBLACK
    }

  .default-section-text {
      font-family: $APPFONTDEFAULT;
      font-weight: 400;
      font-style: normal;
      font-size: 11px;
      line-height: 14px;
      color: $FONTBLACK
    }
}

@media (max-width: 400px) {
  .mobile-padding-left {
    padding-left: .75rem;
  }

  .mobile-margin-top {
    margin-top: .9rem;
  }

  .default-section-text-muted-500 {
      font-family: $APPFONTDEFAULT;
      font-weight: 500;
      font-style: normal;
      font-size: 12px;
      line-height: 12px;
      color: $FONTMUTED
    }

  .default-section-text-500 {
      font-family: $APPFONTDEFAULT;
      font-weight: 500;
      font-style: normal;
      font-size: 11px;
      line-height: 12px;
      color: $FONTBLACK
    }

  .default-section-text {
      font-family: $APPFONTDEFAULT;
      font-weight: 400;
      font-style: normal;
      font-size: 10px;
      line-height: 14px;
      color: $FONTBLACK
    }
}