@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.tab-text {
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  //   font-family: "Montserrat";
  //   font-weight: 500;
  //   font-style: normal;
  //   font-size: 16px;
  //   line-height: 24px;
  //   color: #262a3d;
  background-color: inherit;
  border: none;
}

.active-indicator {
  background-color: #0d47a1;
  height: 2px;
}
.inactive-indicator {
  height: 2px;
}
