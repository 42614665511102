@import "../../../../Styles/Variables/GlobalStyles.scss";

.accordion-button {
    
    &:not(.collapsed) {
        color: $DARKBLUE;
        background-color: $FULLWHITE;
        box-shadow: none;
        border-color: $FULLWHITE;
    }

    &:focus {
        border-color: $FULLWHITE;
        box-shadow: none;
    }
  }

.accordion-item {
    border: 1px solid $DIVIDERGREY;
}
    
     
