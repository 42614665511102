@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.breadcrumb-previous-text {
  font-family: "Montserrat";
  font-weight: "500, Medium";
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: #262a3d;
  cursor: pointer;
}

.breadcrumb-current-text {
  font-family: "Montserrat";
  font-weight: 500, Medium;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: rgb(38, 42, 61, 0.5);
}

.post-title-text {
  font-family: "Montserrat";
  font-weight: 600, Semi Bold;
  font-style: normal;
  font-size: 56px;
  line-height: 72px;
  color: #262a3d;
}

.post-subtitle-text {
  font-family: "Montserrat";
  font-weight: 500, Medium;
  font-style: normal;
  font-size: 28px;
  color: rgba(38, 42, 61, 0.7);
}

.post-content-text {
  font-family: "Montserrat";
  font-weight: 400, Regular;
  font-style: normal;
  font-size: 18px;
  color: #595a5c;
}

.post-section-title {
  font-family: "Montserrat";
  font-weight: "600, Semi Bold";
  font-style: normal;
  font-size: 36px;
  color: #262a3d;
}

.paragraph-section-title {
  font-family: "Montserrat";
  font-weight: "600, Semi Bold";
  font-style: normal;
  font-size: 24px;
  color: #262a3d;
}

.animated {
  background-color: #f3f3f3;
  background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
  animation: placeholderShimmer 2.75s linear 0s infinite normal forwards;
}

.animated-slow {
  background-color: #f3f3f3;
  background: linear-gradient(to right, #f3f3f3 5%, #eee 20%, #f3f3f3 35%);
  animation: placeholderShimmer 25s linear 0s infinite normal forwards;
}

@keyframes placeholderShimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}
