@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
.profile-container {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;
}

.fund-card:hover {
  -webkit-box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08),
    0 0 6px rgba(0, 0, 0, 0.05);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}
.rbt-input-main .form-control .rbt-input {
  border: 1px solid #dadde1 !important;
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #262a3d !important;
}
.rbt-input-hint {
  font-family: "Montserrat" !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #262a3d !important;
  opacity: 50% !important;
}
.stage-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(229, 147, 53, 0.1);
}

.stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #e59335;
}

.fund-card-name-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 24px;
  color: #262a3d;
}
.SignupForm-field-error-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #ff3434;
}
.fund-card-firm-name-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  opacity: 0.5;
  color: #000000;
}

.projection-header-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  opacity: 50%;
}

.projection-value-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  line-height: 20px;
  color: #262a3d;
}

.topBottomBorder {
  border-top: 1px solid #e2e6ed;
  border-bottom: 1px solid #e2e6ed;
}
.btn-class {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 16px !important;
  background-color: #0d47a1 !important;
  color: #ffffff !important;
}

.btn-class:hover {
  background-color: #0e3690 !important;
}
.btn-class:focus,
.btn-class:active {
  outline: none !important;
  box-shadow: none !important;
}

.investment-header-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #262a3d;
  opacity: 0.5;
}

.investment-value-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 22px;
  line-height: 20px;
  color: #262a3d;
}

.allocation-left-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.progressBar-height {
  height: 16px;
}

.error-message-text {
  font-family: "Montserrat";
  font-weight: 500;
  color: #ff011f;
  font-size: 12px;
}
/*# sourceMappingURL=FundCard.css.map */
