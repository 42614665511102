@import "../../ConfirmInvestmentStyles/ConfirmInvestmentStyles.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.category-name {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #262a3d;
}

.category-value {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  color: #262a3d;
}
