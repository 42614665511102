@use "./../../../../../utilities/scss/variables";


.vertical-border-new:after {
  content: "";
  background: variables.$secondary-border;
  position: absolute;
  bottom: 0;
  left: 50%;
  height: 100%;
  width: 1px;
}

.horizontal-border-new:after {
  content: "";
  background: variables.$secondary-border;
  position: absolute;
  bottom: 50%;
  left: 0px;
  height: 1px;
  width: 100%;
}

.horizontal-border-new {
  position: relative;
}

.fund-target-header-text-new {
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-2 !important;
  line-height: 12px !important;
  opacity: 0.5;
  padding-bottom:5px;
}

.two-by-two-grid-new {
  background-color: variables.$two-by-two-grid;
}

.two-by-two-grid-item-new {
    padding-top: 10px;
    padding-left: 15px;
    padding-bottom: 10px;
}


.info-text-new {

  font-weight: variables.$font-weight-3;
  font-style: normal;
  font-size: variables.$font-size-3;
  line-height: 14px;
  color: variables.$primary-black-1;
}


.info-text-header-new {
  opacity: 0.5;
  color: #000000;

}

.projection-value-text-new {

  font-weight: variables.$font-weight-3;
  font-style: normal;
  font-size: variables.$font-size-4;
  line-height: 16px;
  color: variables.$primary-black-1;
}

.projection-target-value-text-new {

  font-weight: variables.$font-weight-3;
  font-style: normal;
  font-size: variables.$font-size-4;
  line-height: 16px;
  color: variables.$primary-black-1;
}
.backdrop-new {
  color: transparent !important;
  text-shadow: 0 0 15px rgba(0, 0, 0, 1) !important;
}

@media screen and (max-width: 1500px) {
  .projection-target-value-text-new {
    font-size: variables.$font-size-4 !important;
  }
  .projection-target-value-text-new .backdrop-new {
    color: transparent !important;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5) !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .info-text-new {
    font-size: 13px !important;
  }
  .fund-target-header-text-new {
    font-size: 11px !important;
  }
}

@media screen and (min-width:768px) and (max-width: 992px) {
  .info-text-new {
    font-size: 12px !important;
  }
  .fund-target-header-text-new {
    font-size: 11px !important;
  }
}
