@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.entity-account-details-back-section {
  margin-top: 80px;
  margin-bottom: 14px;
}

.entity-account-details-back-section-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #262a3d;
}
.entity-account-name-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 20px;
}
.entity-account-type-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: #707070;
}

.entity-account-information-container {
  max-width: 100%;
  background-color: #ffffff;
  border-radius: 6px;
}

.row-bottom-divider {
  border-bottom: 1px solid #e2e6ed;
}

.entity-account-information-container-header {
  padding-left: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.entity-account-information-content-padding {
  padding-top: 24px;
  padding-left: 16px;
  padding-bottom: 24px;
}

.entity-account-plain-text-header-container {
  background-color: #f3f5f7;
  border-radius: 4px;
}

.entity-account-plain-text-header {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #707070;
}
.col-right-border {
  border-right: 1px solid #e2e6ed;
}
.row-bottom-border {
  border-bottom: 1px solid #e2e6ed;
}
.entity-account-qualification-level-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #0d47a1;
}
.entity-account-plain-text-value {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #000000;
}

.verified-component {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  color: #00aa26;
  background: rgba(0, 170, 38, 0.1);
  border-radius: 2px;
}
.pending-verification-component {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  color: #e59335;
  background: rgb(229, 147, 53, 0.1);
  border-radius: 2px;
}
.failed-verification-component {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  color: #dd2447;
  background: rgb(221, 36, 71, 0.1);
  border-radius: 2px;
}

.pending-information-component {
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 4px;
  padding-right: 4px;
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  color: #1d5fc3;
  background: rgba(29,95,195,0.1);
  border-radius: 2px;
  
}




.container-side-borders {
  border-left: 1px solid #e2e6ed;
  border-right: 1px solid #e2e6ed;
}

.entity-account-section-header-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 14px;
  color: #262a3d;
}

.contact-aqua-admin-container {
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #f3f5f7;
  border-radius: 4px;
}
.contact-aqua-admin-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
}
