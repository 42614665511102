@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.SignupFormPage-container {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  height: 100vh;
  background-color: #ffffff;
}
.fillScreen {
  min-height: 100vh;
  overflow-x: hidden;
}
.rectangle1 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  height: 100%;
  background-color: #f0f4fd;
}
.rectangle2 {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 50%;
  background-color: #ffffff;
  display: flex;
}
.SignupForm-container {
  box-sizing: inherit;
  box-shadow: none;
  margin-left: 100px;
  margin-right: 10px;
  width: 444px;
  max-width: 444px;
}
.SignupForm-header {
  margin-bottom: 32px;
}
.SignupForm-header-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 32px;
  line-height: 32px;
  color: #262a3d;
}
.SignupForm-subheader {
  padding-top: 16px;
}
.SignupForm-subheader-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 18px;
  align-self: left;
  vertical-align: top;
  line-height: 24px;
  opacity: 0.5;
}
.SignupForm-field-title-container {
  margin-bottom: 12px;
}
.SignupForm-field-title-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #262a3d;
}
.error-field-container {
  display: flex;
  width: 100%;
  margin-bottom: 0px;
}
.vertical-spacer {
  height: 24px;
}
.SignupForm-name-field-error-container {
  float: left;
  width: 49%;
}
.SignupForm-field-error-container {
  float: left;
}
.SignupForm-field-error-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #cc0000;
}
.name-field-container {
  display: flex;
  width: 100%;
  margin-bottom: 0px;
}
.field-container {
  width: 100%;
  margin-bottom: 0px;
}
.first-name-container {
  float: left;
  width: 49%;
}
.last-name-container {
  float: left;
  width: 49%;
}
.name-field-spacer {
  float: left;
  width: 4%;
}
.button-container {
  float: left;
  max-width: 120px;
}
.signup-bar {
  height: 40px;
  margin-bottom: 72px;
}
.progress-container {
  display: flex;
  max-width: 280px;
  max-height: 2px;
}
.progress-bar-completed {
  height: 0px;
  width: 100%;
  border: 2px solid #0d47a1;
}
.progress-bar-empty {
  height: 0px;
  width: 100%;
  border: 2px solid #dadde1;
}
.progress-spacer {
  width: 13px;
}
.back-arrow-section {
  display: flex;
  max-width: 16px;
  max-height: 14px;
  margin-bottom: 40px;
}
.sign-in-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 40px;
  color: #262a3d;
}

.btn-class {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 16px !important;
  background-color: #0d47a1 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}

.btn-class:hover {
  background-color: #0e3690 !important;
}
.btn-class:focus,
.btn-class:active {
  outline: none !important;
  box-shadow: none !important;
}
.react-tel-input .form-control {
  background-color: #f7f7fa;
  width: 100% !important;
  font-family: "Montserrat";
  color: #262a3d;
}

.similar-background {
  background-color: #f7f7fa !important;
}
