@use "../../../../../../../../../utilities/scss/variables";
@import "../../../../../../../../../utilities/scss/global";

.fillScreen {
  min-height: 100vh;
  overflow-x: hidden;
}

.accredited-investor-questionnaire-section-header-text {
  font-family: variables.$primary-font-family;
  font-weight: 600;
  font-style: normal;
  font-size: 28px;
  line-height: 28px;
  color: #262a3d;
}
.accredited-investor-questionnaire-section-subheader-text {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 20px;
  color: #262a3d;
}
.accredited-investor-questionnaire-gray-text {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #262a3d;
  opacity: 0.5;
}

.radio {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}
.radio__input {
  display: none;
}
.radio__radio {
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #dadde1;
  border-radius: 50%;
  padding: 2px;
  box-sizing: border-box;
}
.radio__radio::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background-color: #0d47a1;
  border-radius: 50%;
  transform: scale(0);
}
.radio__input:checked + .radio__radio::after {
  transform: scale(1);
  transition: transform 0.15s;
}
.question-text {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #262a3d;
}
.selection-text {
  font-family: variables.$primary-font-family;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #262a3d;
}
.sign-in-text {
  font-family: variables.$primary-font-family;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 36px;
  color: #262a3d;
}

.btn-class {
  font-family: variables.$primary-font-family !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 12px !important;
  background-color: #0d47a1 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}

.btn-class:hover {
  background-color: #0e3690 !important;
}
.btn-class:focus,
.btn-class:active {
  outline: none !important;
  box-shadow: none !important;
}

.trust-qualification-error-text {
  font-family: variables.$primary-font-family;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}
