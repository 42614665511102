@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.disable-underline {
  text-decoration: none;
}
.investment-container {
  background-color: #ffffff;
  border-radius: 10px;
}
.investment-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
@media (max-width: 992px) {
  .investment-text {
    font-size: 20px;
  }
}
.investment-subtext {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}
.investment-note {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  opacity: 1.0;
}
.investment-amount-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 18px;
  color: #262a3d;
}
.progress-text-bold {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: #262a3d;
}

.progress-text-unbolded {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
  color: #262a3d;
  opacity: 0.5;
}

.w-90 {
  width: 90%;
}

.btn-class {
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 16px !important;
  background-color: #0d47a1 !important;
  color: #ffffff !important;
  border-radius: 4px !important;
}

.btn-class:hover {
  background-color: #0e3690 !important;
}
.btn-class:focus,
.btn-class:active {
  outline: none !important;
  box-shadow: none !important;
}

.cancel-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #262a3d;
  cursor: pointer;
  opacity: 0.5;
}
.investment-amount-error-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 10px;
  line-height: 10px;
  color: #ff3434;
}

.admin-email-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #0d47a1;
  cursor: pointer;
}

.admin-email-text:hover {
  color: #0e3690;
}

@media (min-width: 992px) {
  .border-right {
    border-right: 1px solid #e2e6ed;
  }
}

.request-received-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  color: #262a3d;
}

.response-text {
  font-family: "Montserrat";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #262a3d;
}

.no-response-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}

.investment-details-header-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 18px;
  color: #262a3d;
}

.selected-fund-header-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  color: #262a3d;
}

.rounded-edges {
  border-radius: 10px;
  box-sizing: border-box;
  border: 1px solid #e2e6ed;
}

.small-fund-name {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #262a3d;
}

.small-firm-name {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  opacity: 0.5;
  color: #000000;
}

.amount-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: #262a3d;
}

.qp-error-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
}

.sub-error-text {
  font-family: "Montserrat";
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
}
