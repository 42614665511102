@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.projection-header-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  opacity: 0.5;
}
.projection-value-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #262a3d;
}

@media screen and (max-width: 1500px) {
  .projection-value-text {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1250px) {
  .projection-value-text {
    font-size: 16px !important;
  }
}
@media screen and (min-width: 811px) and (max-width: 1199px) {
  .projection-value-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 810px) {
  .projection-value-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 415px) {
  .projection-value-text {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 380px) {
  .projection-value-text {
    font-size: 10px !important;
  }
}

.backdrop {
  backdrop-filter: blur(1);
}
