.button-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d47a1;
  border-radius: 4px;
}
.button-box:hover {
  background-color: #0e3690 !important;
}
.button-box-gray {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0d47a1;
  border-radius: 4px;
  opacity: 0.5;
}
.button-text-gray {
  cursor: default;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  opacity: 0.5;
}
.button-text {
  cursor: pointer;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
}
