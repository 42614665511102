@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.menu-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  margin-left: 10px;
  line-height: 35px;
  color: #031b4e;
  font-style: normal;
}

.menu-item-text-name {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  color: #031b4e;
  line-height: 18px;
  font-style: normal;
}

.menu-item-subtitle {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  color: #031b4e;
  font-style: normal;
}
