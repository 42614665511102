@use "./../../../../../utilities/scss/variables";

.allocation-left-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: variables.$font-size-3;
  line-height: 14px;
  color: #000000;
}

.upcoming-text {
  font-family: variables.$primary-font-family;
  font-weight: variables.$font-weight-2;
  font-style: normal;
  font-size: 25px;
  line-height: 25px;
  color: #262a3d;
  letter-spacing: 2px;
}

.progressBar-height {
  height: 16px;
} /*# sourceMappingURL=AllocationGraphSectionStyles.css.map */
