@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");

.investment-accounts-page-title-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 24px;
  color: #262a3d;
}

.contain {
  width: 90%;
}
.page-title {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 32px;
  line-height: 20px;
  color: #262a3d;
}

.w-98 {
  width: 99%;
}
.table-style {
  background-color: #ffffff;
  border-radius: 10px;
  padding-left: 10px !important;
}
.table-header-style {
  width: 100% !important;
  background-color: #000000;
  border-radius: 10px !important;
  border-bottom: hidden !important;
}

.table-data-style {
  cursor: pointer !important;
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #262a3d !important;
  border-bottom: hidden !important;
  align-items: center !important;
  vertical-align: middle !important;
}
.spacer {
  height: 16px;
}
.table-row-style:first-child {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.table-row-style:first-child:hover {
  margin-top: 16px !important;
}
.table-row-style:hover {
  cursor: pointer !important;
  background-color: #f3f5f7 !important;
}
// tbody {
//   margin-top: 15px !important;
//   display: block !important;
// }
tbody:before {
  content: "@" !important;
  display: block !important;
  line-height: 16px !important;
  text-indent: -99999px !important;
}
.table-row-secondary-information-container {
  border: 2px solid #e2e6ed;
  box-sizing: border-box;
  border-radius: 4px;
}
.table-row-secondary-information-divider {
  border-right: 1px solid #e2e6ed;
}
.table-row-secondary-information-section-title {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #262a3d;
}
.table-row-secondary-information-section-value {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 16px;
  color: #262a3d;
}
.table-row-style:focus {
  cursor: pointer !important;
  background-color: #e6e8ed !important;
}
.table-row-style-focus {
  cursor: pointer !important;
  background-color: #e6e8ed !important;
}
td {
  border: hidden !important;
}
.table-row-style:first-child {
  cursor: pointer !important;
  margin-top: 16px !important;
}
.table-data-style:first-child {
  padding-left: 15px !important;
}
.start-padding {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  background-color: #f3f5f7 !important;
  border: none !important;
  outline: none !important;
  //   .th:first-child {
  //     border-radius: 10px 0 0 10px !important;
  //   }
  //   .th:last-child {
  //     border-radius: 0 10px 10px 0 !important;
  //   }
}
.react-bs-container-body tr {
  border-top-style: hidden !important;
}

.table {
  border-top: hidden !important;
  border-bottom: hidden !important;
}
.react-bs-container-header tr {
  border-bottom-style: hidden !important;
}
.start-padding:first-child {
  padding-left: 15px !important;
  border-radius: 10px 0 0 10px !important;
}
.start-padding:last-child {
  border-radius: 0 10px 10px 0 !important;
}

.table-header-text {
  font-family: "Montserrat" !important;
  font-weight: 600 !important;
  font-style: normal !important;
  font-size: 12px !important;
  line-height: 12px !important;
  color: #262a3d !important;
  border: hidden !important;
  border-bottom: hidden !important;
}
.table .thead .tr .th:first-child {
  border-radius: 10px 0 0 10px;
}

.table .thead .tr .th:last-child {
  border-radius: 0 10px 10px 0;
}
.add-account-button {
  font-family: "Montserrat";
  font-style: normal;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #ffffff;
}
.add-account-button:hover {
  color: #eeeeee;
}
.account-button-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #262a3d;
}

.topBorderClass {
  border-top: 1px solid #e2e6ed;
}

.star-vector {
  position: absolute;
  left: 0%;
  right: 0%;
  top: 2.15%;
  bottom: 2.02%;
  background-color: #0d47a1;
}

.table-row-style-blue:focus {
  cursor: pointer !important;
  background-color: rgba(13, 71, 161, 0.1) !important;
}
.table-row-style-focus-blue {
  cursor: pointer !important;
  background-color: rgba(13, 71, 161, 0.1) !important;
}
.table-row-style-blue:first-child {
  cursor: pointer !important;
  margin-top: 16px !important;
}

.table-row-style-blue:first-child {
  height: 5px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.table-row-style-blue:first-child:hover {
  margin-top: 16px !important;
}
.table-row-style-blue:hover {
  cursor: pointer !important;
  background-color: #f3f5f7 !important;
}

.green-circle {
  border-radius: 50%;
  background-color: #00aa26;
  height: 10px;
  width: 10px;
}

.orange-circle {
  border-radius: 50%;
  background-color: #e59335;
  height: 10px;
  width: 10px;
}

.blue-circle {
  border-radius: 50%;
  background-color: #1d5fc3;
  height: 10px;
  width: 10px;
}

.red-circle {
  border-radius: 50%;
  background-color: #dd2447;
  height: 10px;
  width: 10px;
}
