@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.account-legal-information-container {
  background-color: #ffffff;
  border-radius: 10px;
}
.account-legal-information-outer-column-padded {
  padding-left: 32px;
  padding-top: 32px;
  padding-right: 32px;
  padding-bottom: 32px;
}
.account-legal-information-step-subheader {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #262a3d;
}
