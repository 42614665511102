@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
.input-box-container {
  border: 1px solid #dadde1;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.input-box-containerFocus {
  border: 1px solid #0d47a1;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.input-box-containerError {
  border: 1px solid #ff3434;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  -webkit-box-align: center;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.input-box {
  box-sizing: border-box;
  display: block;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}
.internal-signature-input {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  height: 100%;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
  border-top-color: initial;
  border-top-style: none;
  border-top-width: initial;
  border-right-color: initial;
  border-right-style: none;
  border-right-width: initial;
  border-bottom-color: initial;
  border-bottom-style: none;
  border-bottom-width: initial;
  border-left-color: initial;
  border-left-style: none;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #262a3d;
  width: 100%;
}

.internal-signature-input::placeholder {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  height: 100%;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
  border-top-color: initial;
  border-top-style: none;
  border-top-width: initial;
  border-right-color: initial;
  border-right-style: none;
  border-right-width: initial;
  border-bottom-color: initial;
  border-bottom-style: none;
  border-bottom-width: initial;
  border-left-color: initial;
  border-left-style: none;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #262a3d;
  width: 100%;
}

.internal-signature-input-error-empty {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px;
  height: 100%;
  outline-color: initial;
  outline-style: none;
  outline-width: initial;
  border-top-color: initial;
  border-top-style: none;
  border-top-width: initial;
  border-right-color: initial;
  border-right-style: none;
  border-right-width: initial;
  border-bottom-color: initial;
  border-bottom-style: none;
  border-bottom-width: initial;
  border-left-color: initial;
  border-left-style: none;
  border-left-width: initial;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  background-image: initial;
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-repeat-x: initial;
  background-repeat-y: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  color: #ff3434;
  width: 100%;
}
.internal-signature-input-error-empty::placeholder {
  font-family: "Montserrat", cursive;
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  color: #ff3434;
  opacity: 1;
  border: none;
}

.new-font {
  font-family: "Montserrat", cursive;
  font-size: 18px;
  font-weight: 400;
}
