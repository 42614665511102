@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.component-container {
  background-color: #ffffff;
  border: 1px solid #e2e6ed;
  max-width: 600px;
  border-radius: 4px;
}

.message-container {
  max-width: 213px;
  border-right: 1px solid #e2e6ed;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.investment-instructions-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  color: #262a3d;
}
.complete-steps-container {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
}

.step-1-container {
  padding-bottom: 8px;
}
.step-2-container {
  padding-top: 8px;
}

.complete-steps-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 12px;
  color: #262a3d;
}
.complete-steps-weighted-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  color: #262a3d;
}
.bottom-border-only {
  border-bottom: 1px solid #e2e6ed;
}
.complete-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 12px;
  color: #0d47a1;
  border-radius: 4px;
}
