@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
.custom-triangle {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 10px solid black;
  cursor: pointer;
  transition: all 0.25s;
  transform: rotate(90deg);
}

.preview-font {
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 600;
  color: #262a3d;
  cursor: pointer;
}

.detail-font {
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  color: #262a3d;
}
