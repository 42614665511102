@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.upcoming-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(229, 147, 53, 0.1);
}

.stage-text {
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  color: white !important;
}

.upcoming-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #e59335;
}

.currently-fundraising-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(0, 170, 38, 0.1);
}

.currently-fundraising-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #00aa26;
}

.fundraising-complete-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(100, 66, 207, 0.1);
}

.fundraising-complete-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #6442cf;
}

.closed-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(29, 95, 195, 0.1);
}

.closed-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #1d5fc3;
}

.past-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #dd2447;
}/*# */

.new-upcoming-stage {
  background-color: #E59335;
  color: white;
}

.new-fundraising-stage {
  background-color: rgb(0, 170, 38);
  color: white;
}

.new-pending-closing-stage {
  background-color: #6442cf;
  color: white;
}

.new-closed-stage {
  background-color:  #1d5fc3;
  color: white

}

.new-past-stage {
  background-color: #dd2447;
  color: white;
}

.new-upcoming-stage {
  background-color: #e59335;
  color: white;
}

.stage-text {
  margin-bottom: "5px";
  margin-top: "5px"
}

.currently-fundraising-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: rgba(0, 170, 38, 0.1);
}

.currently-fundraising-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #00aa26;
}

.fundraising-complete-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: #6442cf;
}

.fundraising-complete-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #6442cf;
}

.closed-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: #1d5fc3;
}

.closed-stage-text {
  padding: 10px;
  font-family: "Montserrat";
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #1d5fc3;
}

.past-stage-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  border-radius: 4px;
  background-color: #dd2447;

}