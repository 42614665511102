@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");

.stepper-hyperlink-text {
  font-family: "Montserrat";
  font-weight: 600;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
}

.step {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fff;
}

.step-body {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.step-actions {
  display: inline-flex;
  justify-content: space-between;
  margin: 0 2rem 1rem;
}

.step-button {
  padding: 0.5rem 1rem;
  border: none;
}
